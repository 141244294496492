button, .button {
    @apply py-2 px-6 bg-green-primary text-white rounded leading-tight transition-all duration-300 ease-in-out;
    @screen md {
        @apply py-4 px-10;
    }
    &:hover {
        @apply bg-green-800;
    }
}

a.button {
    @apply inline-block;
    &:hover {
        @apply text-white;
    }
}

.button-small {
    @apply py-2 px-6;
}