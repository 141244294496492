.w-content, .w-content-narrow, .w-content-copy {
    @apply max-w-11/12 mx-auto;
}
.w-content {
    @screen xl {
        @apply max-w-6xl;
    }
}
.w-content-narrow {
    @screen md {
        @apply max-w-lg;
    }
    @screen xl {
        @apply max-w-3xl;
    }
}
.w-content-copy {
    @screen md {
        @apply max-w-md;
    }
    @screen xl {
        @apply max-w-xl;
    }
}

.content-wrap {
    h3 {
        @apply uppercase;
    }
    h3 a {
        @apply text-green-primary;
    }
    ul li, ol li {
        @apply mb-1;
    }
}