.grid {
    display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-auto-rows: minmax(35rem, auto);
    @apply justify-center max-w-sm mx-auto mb-16 px-4;
    @screen sm {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    }

    @screen md {
        @apply max-w-md;
    }
    @screen lg {
        @apply max-w-4xl w-4/5;
    }
    @screen max {
        @apply justify-between max-w-6xl w-full;
    }
    &-home {
        grid-auto-rows: minmax(20rem, auto);
        @screen lg {
            grid-auto-rows: minmax(35rem, auto);
        }
    }
}

.grid-box {
    @apply relative w-full mx-auto shadow bg-white;


    &:hover {
        @apply no-underline;
        box-shadow: 2px 2px 3px 1px rgba(0,0,0,0.3);
    }

    &-home {
        @apply bg-cover bg-center text-center;
    }

    @supports (display:grid) {
        @screen sm {
          @apply w-auto mx-0 mb-0;
        }
    }
}

.grid-box-inner {
    @apply flex justify-center items-end h-full px-4 text-white uppercase bg-black-transparent-light;
    transition: all 0.25s ease-in;
    &:hover {
        @apply bg-black-transparent;
    }
}
