.subnav-item, a.subnav-item {
    @apply flex justify-center items-center mr-4 mb-4 py-3 px-3 bg-green-primary-dark text-center text-white no-underline;
    width: 12.5rem;
    &:hover {
        @apply bg-green-primary text-white transition-all duration-300 ease-in-out;
    }
    @screen md {
        width: 12.5rem;
        &:nth-of-type(4n) {
            @apply mr-0;
        }
    }

    h2 {
        @apply font-thin my-0 text-lg;
    }
}