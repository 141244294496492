/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
/* this file is for public and private/admin pages */
a, .button-link {
  @apply text-green-primary no-underline transition-colors duration-300 ease-in-out;
}

a:hover, a:active, .button-link:hover, .button-link:active {
  @apply underline text-green-800;
}

a:disabled, .button-link:disabled {
  @apply opacity-75;
}

a:disabled:hover, .button-link:disabled:hover {
  @apply no-underline cursor-default;
}

.button-link {
  @apply font-light;
}

b, strong {
  @apply font-bold;
}

ul, ol {
  @apply pl-10;
}

ul li, ol li {
  @apply mb-4;
}

ul li:last-of-type, ol li:last-of-type {
  @apply mb-0;
}

ul {
  @apply list-disc;
}

ol {
  @apply list-decimal;
}

address {
  @apply not-italic;
}

td {
  @apply align-top;
}

.transition {
  @apply transition-all duration-300 ease-in-out;
}

body {
  @apply font-body leading-loose text-gray-800;
}

.alert {
  @apply flex justify-between mt-0 mx-auto mb-1 p-2 bg-gray-200 border-l-10;
}

.form-wrap .alert {
  @apply w-full;
}

.alert-success {
  @apply border-green-500;
}

.alert-info {
  @apply border-blue-400;
}

.alert-warning {
  @apply border-orange-500;
}

.alert-danger {
  @apply border-red-500;
}

.content-img-center {
  @apply block mx-auto my-4;
}

.content-img-left {
  @apply float-left my-4 mr-4;
}

.content-img-right {
  @apply float-right my-4 ml-4;
}

[type=text],
[type=password],
[type=date],
[type=datetime],
[type=datetime-local],
[type=month],
[type=week],
[type=email],
[type=number],
[type=search],
[type=tel],
[type=time],
[type=url],
[type=color],
textarea {
  @apply w-full px-2 py-1 text-black border border-gray-400 rounded-sm bg-gray-100 transition-colors duration-300 ease-in-out;
}

[type=text]:focus,
[type=password]:focus,
[type=date]:focus,
[type=datetime]:focus,
[type=datetime-local]:focus,
[type=month]:focus,
[type=week]:focus,
[type=email]:focus,
[type=number]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=time]:focus,
[type=url]:focus,
[type=color]:focus,
textarea:focus {
  @apply border-gray-500 bg-gray-100;
}

textarea {
  @apply align-top text-black;
}

select {
  @apply w-full p-1 text-black border border-gray-400 rounded-sm bg-gray-200 transition-colors duration-300 ease-in-out;
  height: 2.14rem;
}

select:focus {
  @apply border-gray-500 bg-gray-100;
}

[type=checkbox] {
  @apply mr-2;
}

[type=radio] {
  @apply relative mr-1;
  top: -1px;
}

[type=submit], [type=image], label, select {
  @apply cursor-pointer;
}

[type=file] {
  @apply w-full;
}

.form-wrap {
  @apply w-11/12 max-w-lg mx-auto my-4 p-4 rounded-sm shadow-md;
}

.field-wrap {
  @apply mb-4;
}

.field-wrap label {
  @apply block mb-1;
}

.field-wrap-checkbox {
  @apply flex flex-wrap items-center mb-4;
}

.field-wrap-checkbox label {
  @apply inline-block mr-4 mb-0;
}

.field-wrap-radio {
  @apply mb-1;
}

.field-wrap-radios, .field-wrap-checkboxes {
  @apply mb-4;
}

.field-wrap-radios label, .field-wrap-checkboxes label {
  @apply inline-block mr-4 mb-0;
}

.field-wrap-radios legend, .field-wrap-checkboxes legend {
  @apply mb-1;
}

.field-wrap-radios .field-wrap-checkbox, .field-wrap-checkboxes .field-wrap-checkbox {
  @apply mb-1;
}

.field-errors {
  @apply mb-1 pl-0 list-none;
}

.field-errors li {
  @apply mb-1 p-2 bg-gray-200 border-l-10 border-red-700;
}

.field-wrap-readonly {
  @apply relative;
}

.field-wrap-readonly input {
  @apply opacity-50;
}

.field-wrap-readonly:after {
  @apply absolute right-0 text-xs opacity-50;
  top: 0.5rem;
  content: "read-only";
}

.field-help {
  @apply w-full mt-px text-xs italic text-gray-600 leading-tight;
  padding-left: 0.1em;
}

.form-action {
  @apply text-sm ml-4;
}

h1, h2, h3, h4, h5, h6 {
  @apply leading-tight font-bold;
}

h1 {
  @apply text-3xl;
  margin: 0.67rem 0;
}

h2 {
  @apply text-2xl;
  margin: 0.83rem 0;
}

h3 {
  @apply text-xl mt-8 mb-2;
}

h4 {
  @apply mt-6 mb-2;
}

h5 {
  @apply text-base;
  margin: 1.67rem 0;
}

@screen md {
  h5 {
    @apply text-sm;
  }
}

h6 {
  @apply text-xs mt-6 mb-2;
}

h3 + p, h4 + p {
  @apply mt-0;
}

h1 a, h2 a, h3 a, h4 a {
  @apply no-underline text-inherit;
}

h1 a:hover, h1 a:active, h2 a:hover, h2 a:active, h3 a:hover, h3 a:active, h4 a:hover, h4 a:active {
  @apply underline text-inherit;
}

p {
  @apply my-4;
}

.md-enter-active,
.md-leave-active {
  @apply transition-all duration-500;
}

.md-enter,
.md-leave-active {
  @apply opacity-0;
  transform: translateY(24px);
}

button, .button {
  @apply py-2 px-6 bg-green-primary text-white rounded leading-tight transition-all duration-300 ease-in-out;
}

@screen md {
  button, .button {
    @apply py-4 px-10;
  }
}

button:hover, .button:hover {
  @apply bg-green-800;
}

a.button {
  @apply inline-block;
}

a.button:hover {
  @apply text-white;
}

.button-small {
  @apply py-2 px-6;
}

.banner {
  @apply w-full bg-cover bg-center bg-gray-400;
  height: 30rem;
  background-image: url("/images/okanagan-residential-construction.jpg");
}

@screen lg {
  .banner {
    height: 35rem;
  }
}

.banner-small {
  height: 10rem;
  background-image: none;
}

@screen lg {
  .banner-small {
    height: 11rem;
  }
}

.banner-gallery {
  background-image: url("/images/uber-gallery-banner.jpg");
}

.banner-hp {
  height: 40rem;
  background-image: url("/images/uber-commercial-construction.jpg");
}

@screen lg {
  .banner-hp {
    height: 50rem;
  }
}

.banner-renovations {
  background-image: url("/images/uber-residential-renovations.jpg");
}

.w-content, .w-content-narrow, .w-content-copy {
  @apply max-w-11/12 mx-auto;
}

@screen xl {
  .w-content {
    @apply max-w-6xl;
  }
}

@screen md {
  .w-content-narrow {
    @apply max-w-lg;
  }
}

@screen xl {
  .w-content-narrow {
    @apply max-w-3xl;
  }
}

@screen md {
  .w-content-copy {
    @apply max-w-md;
  }
}

@screen xl {
  .w-content-copy {
    @apply max-w-xl;
  }
}

.content-wrap h3 {
  @apply uppercase;
}

.content-wrap h3 a {
  @apply text-green-primary;
}

.content-wrap ul li, .content-wrap ol li {
  @apply mb-1;
}

#silentbox-group {
  @apply text-center;
}

#silentbox-overlay {
  z-index: 9999 !important;
}

.silentbox-item, .team-item {
  @apply relative inline-block w-4/5 mx-auto mb-4 overflow-hidden;
  max-width: 450px;
}

.silentbox-item img, .team-item img {
  transition: all 0.5s ease;
}

@screen md {
  .silentbox-item, .team-item {
    @apply ml-0 mr-4;
    width: 30%;
  }
  .silentbox-item:nth-of-type(3n), .team-item:nth-of-type(3n) {
    @apply mr-0;
  }
}

.silentbox-item:hover {
  @apply overflow-hidden;
}

.silentbox-item:hover img {
  transform: scale(1.05);
}

.team-item {
  @apply text-center mb-10;
  max-width: 350px;
}

@screen lg {
  .team-item {
    @apply mr-6;
  }
}

.gallery-inner {
  @apply absolute flex items-center justify-center opacity-0 w-full h-full text-white z-10 text-center transition-all duration-300 ease-in-out;
}

.gallery-inner:hover {
  @apply opacity-100;
  background-color: rgba(139, 199, 74, 0.75);
}

.grid {
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-auto-rows: minmax(35rem, auto);
  @apply justify-center max-w-sm mx-auto mb-16 px-4;
}

@screen sm {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
}

@screen md {
  .grid {
    @apply max-w-md;
  }
}

@screen lg {
  .grid {
    @apply max-w-4xl w-4/5;
  }
}

@screen max {
  .grid {
    @apply justify-between max-w-6xl w-full;
  }
}

.grid-home {
  grid-auto-rows: minmax(20rem, auto);
}

@screen lg {
  .grid-home {
    grid-auto-rows: minmax(35rem, auto);
  }
}

.grid-box {
  @apply relative w-full mx-auto shadow bg-white;
}

.grid-box:hover {
  @apply no-underline;
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.3);
}

.grid-box-home {
  @apply bg-cover bg-center text-center;
}

@supports (display: grid) {
  @screen sm {
    .grid-box {
      @apply w-auto mx-0 mb-0;
    }
  }
}

.grid-box-inner {
  @apply flex justify-center items-end h-full px-4 text-white uppercase bg-black-transparent-light;
  transition: all 0.25s ease-in;
}

.grid-box-inner:hover {
  @apply bg-black-transparent;
}

.header-nav_item {
  @apply font-display mr-12 text-xl mb-0;
}

.header-nav_item a {
  @apply text-white;
}

.header-nav_item a:hover {
  @apply text-green-primary no-underline;
}

.header-top {
  @apply bg-black py-4;
}

.header-top a {
  @apply text-white;
}

.header-top a:hover {
  @apply text-green-primary no-underline;
}

.header-menu {
  @apply text-right border border-white px-3 py-2 text-white;
}

@screen xl {
  .header-menu {
    @apply hidden;
  }
}

.header-menu:hover {
  @apply text-white font-bold;
}

.header-subnav {
  @apply hidden;
}

.header-subnav-icon {
  @apply hidden w-4 h-4 ml-auto;
}

@screen xl {
  .header-subnav-icon {
    @apply block opacity-0 mx-auto;
  }
}

.header-subnav-show .header-subnav {
  @apply hidden font-display justify-end mt-4 pr-24 transition-all duration-300 ease-in-out;
}

@screen xl {
  .header-subnav-show .header-subnav {
    @apply flex;
  }
}

.header-subnav-show .header-subnav-mobile {
  @apply block pr-0;
}

@screen md {
  .header-subnav-show .header-subnav-mobile {
    @apply hidden;
  }
}

.header-subnav-show .header-subnav-mobile .header-subnav-item {
  @apply mr-0;
}

.header-subnav-show .header-subnav-mobile .header-subnav-icon {
  @apply block;
}

.header-subnav-show .header-subnav-icon {
  @apply opacity-100 text-white fill-current;
}

a.header-subnav-item {
  @apply block mr-2 px-4 py-1 text-white bg-green-primary-dark shadow;
}

a.header-subnav-item:last-of-type {
  @apply mr-0;
}

a.header-subnav-item:hover {
  @apply text-white no-underline shadow-lg bg-green-primary transition-all duration-300 ease-in-out;
}

.header_mobile-open ul {
  @apply block mt-4 pl-0 text-right text-lg;
}

.header_mobile-open a.header-menu:hover, .header_mobile-open a.header-menu:focus, .header_mobile-open a.header-menu:active {
  @apply no-underline text-white;
}

.header_mobile-open .header-nav_item {
  @apply mb-1 mr-0 py-1 transition-all duration-300 ease-in-out;
}

.header_mobile-open .header-subnav-item {
  @apply text-base mb-1;
}

.header_mobile-open ~ div {
  @apply relative;
  margin-top: -30rem;
  z-index: -1;
}

.intro-wrap {
  @apply items-center w-5/6 mx-auto py-8;
}

@screen lg {
  .intro-wrap {
    @apply flex py-16;
  }
}

@screen lg {
  .intro-title {
    @apply w-1/3;
  }
}

.intro-content {
  @apply pt-8;
}

@screen lg {
  .intro-content {
    @apply w-2/3 pl-8;
  }
}

.intro-heading {
  @apply text-3xl;
}

@screen lg {
  .intro-heading {
    @apply text-5xl;
  }
}

.intro-subheading {
  @apply font-thin my-0 uppercase;
}

.intro-divider {
  @apply bg-green-primary w-1/2 h-1 mt-8;
}

.subnav-item, a.subnav-item {
  @apply flex justify-center items-center mr-4 mb-4 py-3 px-3 bg-green-primary-dark text-center text-white no-underline;
  width: 12.5rem;
}

.subnav-item:hover, a.subnav-item:hover {
  @apply bg-green-primary text-white transition-all duration-300 ease-in-out;
}

@screen md {
  .subnav-item, a.subnav-item {
    width: 12.5rem;
  }
  .subnav-item:nth-of-type(4n), a.subnav-item:nth-of-type(4n) {
    @apply mr-0;
  }
}

.subnav-item h2, a.subnav-item h2 {
  @apply font-thin my-0 text-lg;
}

.team-item {
  @apply p-8 bg-gray-200 border border-gray-800;
}

@tailwind utilities;
