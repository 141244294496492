/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@import "common";

// global styles on the most common elements
body {
    @apply font-body leading-loose text-gray-800;
}

// common styles
@import "alerts",
    "content_alignment",
    "forms",
    "headings",
    "modal";

// public only styles
@import "buttons",
    "banner",
    "content",
    "gallery",
    "grid",
    "header",
    "intro",
    "subnav",
    "team";

// page specific
//@import ;

@tailwind utilities;