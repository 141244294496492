.header-nav_item {
    @apply font-display mr-12 text-xl mb-0;

    a {
        @apply text-white;
        &:hover {
            @apply text-green-primary no-underline;
        }
    }
}

.header-top {
    @apply bg-black py-4;
    a {
        @apply text-white;
        &:hover {
            @apply text-green-primary no-underline;
        }
    }
}

.header-menu {
    @apply text-right border border-white px-3 py-2 text-white;
    @screen xl {
        @apply hidden;
    }
    &:hover {
        @apply text-white font-bold;
    }
}

.header-subnav {
    @apply hidden;
}
.header-subnav-icon {
    @apply  hidden w-4 h-4 ml-auto;
    @screen xl {
        @apply block opacity-0 mx-auto;
    }
}

.header-subnav-show {
    .header-subnav {
        @apply hidden font-display justify-end mt-4 pr-24 transition-all duration-300 ease-in-out;
        @screen xl {
            @apply flex;
        }
    }
    .header-subnav-mobile {
        @apply block pr-0;

        @screen md {
            @apply hidden;
        }
        .header-subnav-item {
            @apply mr-0;
        }
        .header-subnav-icon {
            @apply block;
        }
    }
    .header-subnav-icon {
        @apply opacity-100 text-white fill-current;
    }

}

a.header-subnav-item {
    @apply block mr-2 px-4 py-1 text-white bg-green-primary-dark shadow;
    &:last-of-type {
        @apply mr-0;
    }
    &:hover {
        @apply text-white no-underline shadow-lg bg-green-primary transition-all duration-300 ease-in-out;
    }
}

.header_mobile-open {
    ul {
        @apply block mt-4 pl-0 text-right text-lg;
    }
    a.header-menu {
        &:hover, &:focus, &:active {
            @apply no-underline text-white;
        }
    }
    .header-nav_item {
        @apply mb-1 mr-0 py-1 transition-all duration-300 ease-in-out;
    }

    .header-subnav-item {
        @apply text-base mb-1;
    }

    & ~ div {
        @apply relative;
        margin-top: -30rem;
        z-index: -1;
    }
}