#silentbox-group {
    @apply text-center;
}

#silentbox-overlay {
    z-index: 9999 !important;
}

.silentbox-item, .team-item {
    @apply relative inline-block w-4/5 mx-auto mb-4 overflow-hidden;
    max-width: 450px;
    img { transition: all 0.5s ease; }

    @screen md {
        @apply ml-0 mr-4;
        width: 30%;
        &:nth-of-type(3n) {
            @apply mr-0;
        }
    }
}

.silentbox-item {
    &:hover {
        @apply overflow-hidden;
        img {
            transform: scale(1.05);
        }
    }
}

.team-item {
    @apply text-center mb-10;
    max-width: 350px;
    @screen lg {
        @apply mr-6;
    }
}

.gallery-inner {
    @apply absolute flex items-center justify-center opacity-0 w-full h-full text-white z-10 text-center transition-all duration-300 ease-in-out;
    &:hover {
        @apply opacity-100;
        background-color: rgba(139,199,74, 0.75);

    }
}