.banner {
  @apply w-full bg-cover bg-center bg-gray-400;
  height: 30rem;
  background-image: url('/images/okanagan-residential-construction.jpg');
  @screen lg {
    height: 35rem;
  }
}

.banner-small {
  height: 10rem;
  background-image: none;
  @screen lg {
    height: 11rem;
  }
}

.banner-gallery {
  background-image: url('/images/uber-gallery-banner.jpg');
}
.banner-hp {
  height: 40rem;
  background-image: url('/images/uber-commercial-construction.jpg');
  @screen lg {
    height: 50rem;
  }
}
.banner-renovations {
  background-image: url('/images/uber-residential-renovations.jpg');
}