.intro-wrap {
    @apply items-center w-5/6 mx-auto py-8;
    @screen lg {
        @apply flex py-16;
    }
}

.intro-title {
    @screen lg {
        @apply w-1/3;
    }
}

.intro-content {
    @apply pt-8;
    @screen lg {
        @apply w-2/3 pl-8;
    }
}

.intro-heading {
    @apply text-3xl;
    @screen lg {
        @apply text-5xl;
    }
}
.intro-subheading {
    @apply font-thin my-0 uppercase;
}

.intro-divider {
    @apply bg-green-primary w-1/2 h-1 mt-8;
}